<template>
  <div class="justify-content-around" :class="{ 'd-flex h-100': inProgress }">
    <v-progress-circular
      indeterminate
      color="white"
      :size="50"
      :width="5"
      v-if="inProgress"
      class="align-self-center"
    ></v-progress-circular>

    <div v-if="!inProgress">
      <div class="row bg-gray-600 rounded d-flex justify-content-center">
        <h1 class="font-weight-bolder my-auto py-4 text-center text-white">
          {{ today }}
        </h1>
      </div>

      <div class="row p-0 m-0">
        <!-- total consume -->
        <div class="col-7 text-center bg-white rounded p-0 mr-2 py-10 mt-4">
          <h2 class="font-weight-bolder mb-3 text-dark text-center">
            {{ $t("DASHBOARD.GLOBAL_PRODUCE") }}
          </h2>
          <div class="mr-3 d-flex justify-content-end">
            <button
              class="btn btn-info"
              :disabled="exportInProgressDay"
              @click="exportDashboardDay()"
            >
              <span>Export</span>
              <v-progress-circular
                indeterminate
                color="primary"
                :size="15"
                :width="2"
                v-if="exportInProgressDay"
                class="ml-3"
              ></v-progress-circular>
            </button>
          </div>
          <div class="row d-flex justify-content-around my-auto">
            <div class="my-auto">
              <div class="d-flex">
                <h2 class="font-xxl text-center mr-4">
                  {{
                    Number(globalPrediction.today.total).toLocaleString(
                      getLanguage(),
                      { maximumFractionDigits: 2 }
                    )
                  }}
                  MW
                </h2>
              </div>
              <div class="my-auto max-auto" v-if="globalPrediction.today.percentage">
                <!-- percentage colors are inverted -->
                <h1
                  class="text-center font-xl"
                  :class="[
                    globalPrediction.today.percentage >= 0
                      ? 'text-success'
                      : 'text-danger',
                    'font-weight-boldest',
                  ]"
                >
                  {{ globalPrediction.today.percentage }}%
                </h1>
              </div>
            </div>
            <!-- consume barchart yesterday today -->
            <div class="">
              <apexchart
                type="bar"
                :options="todayChartOptions"
                :series="todaySeries"
                class="w-100"
                height="200"
              ></apexchart>
            </div>
          </div>
        </div>
        <!-- events -->
        <div class="col bg-white rounded px-8 ml-2 py-10 mt-4">
          <h2 class="font-weight-bolder mb-3 text-dark text-center">
            {{ $t("DASHBOARD.EVENTS") }}
          </h2>

          <div
            v-if="globalPrediction.today.events.length == 0"
            class="text-center"
          >
            <!-- no events -->
            <h2 class="col font-weight-bolder text-center">
              {{ $t("DASHBOARD.NO_EVENTS") }}
            </h2>
            <i class="mt-10 fa-solid fa-calendar-days fa-5x"></i>
          </div>
          <table v-else class="table">
            <thead class="bg-primary text-white">
              <tr>
                <th class="text-center">{{ $t("DASHBOARD.EVENT_TYPE") }}</th>
                <th class="text-center">{{ $t("DASHBOARD.EVENT_NO") }}</th>
              </tr>
            </thead>
            <tr
              v-for="(event, index) in globalPrediction.today.events"
              :key="index"
            >
              <td class="text-center">
                <i
                  class="fa-2x col-2 align-bottom"
                  :class="eventIcons[event.eventType]"
                  :style="{ color: colors[event.eventType] }"
                ></i>
                <span class="col-4 font-weight-bolder ml-4">
                  {{ event.eventType }}
                </span>
              </td>
              <td class="text-center">
                <span v-if="event.count > 0" class="col font-weight-bolder">
                  {{ event.count }}
                </span>
                <span v-else class="col font-weight-bolder">
                  {{ $t("DASHBOARD.ALL") }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="m-0 bg-white rounded px-3 py-10 mt-4" v-if="globalPrediction.today.companies && globalPrediction.today.companies.length > 0">
        <h2 class="m-0 font-weight-bolder mb-3 text-dark text-center">
          {{ $t("DASHBOARD.TOP_3_PRODUCTION") }}
        </h2>
        <!-- top 3 companies -->
        <div class="row d-flex justify-content-around">
          <div
            class="col-3 text-center rounded px-3 py-10 mt-4 company"
            v-for="company in globalPrediction.today.companies"
            :key="company.name"
          >
            <h2 class="font-weight-bolder mb-3">{{ company.name }}</h2>
            <h3>
              {{ $t("DASHBOARD.PRODUCE") }}:
              {{ Number(company.consume).toLocaleString() }} MW
            </h3>
            <h3>
              {{ $t("DASHBOARD.PERCENTAGE") }}:
              <span
                :class="[
                  company.percentage <= 0 ? 'text-success' : 'text-danger',
                  'font-weight-boldest',
                ]"
                >{{
                  company.percentage == null
                    ? "-"
                    : company.percentage.toFixed(2)
                }}%</span
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="row bg-gray-600 rounded d-flex justify-content-center my-4">
        <h1 class="font-weight-bolder my-auto py-4 text-center text-white">
          {{ thisMonth }}
        </h1>
      </div>
      <div class="m-0 rounded px-3 py-10 bg-white">
        <div class="row m-0 d-flex justify-content-end">
          <button
            class="btn btn-info mb-4"
            :disabled="exportInProgress"
            @click="exportDashboard()"
          >
            <span>Export</span>
            <v-progress-circular
              indeterminate
              color="primary"
              :size="15"
              :width="2"
              v-if="exportInProgress"
              class="ml-3"
            ></v-progress-circular>
          </button>
        </div>

        <apexchart
          type="line"
          :options="monthChartOptions"
          :series="monthSeries"
          height="300"
          class="w-100"
        ></apexchart>
      </div>
      <div class="m-0 bg-white rounded px-3 py-10 mt-4" v-if="globalPrediction.month.companies && globalPrediction.month.companies.length > 0">
        <h2 class="m-0 font-weight-bolder mb-3 text-dark text-center">
          {{ $t("DASHBOARD.TOP_3_PRODUCTION") }}
        </h2>
        <!-- top 3 companies -->
        <div class="row d-flex justify-content-around">
          <div
            class="col-3 text-center rounded px-3 py-10 mt-4 company"
            :class="`company-${index}`"
            v-for="(company, index) in globalPrediction.month.companies"
            :key="company.cui"
          >
            <h2 class="font-weight-bolder mb-3">{{ company.name }}</h2>
            <h3>
              {{ $t("DASHBOARD.PRODUCE") }}:
              {{ Number(company.consume).toLocaleString() }} MW
            </h3>
            <h3>
              {{ $t("DASHBOARD.PERCENTAGE") }}:
              <span
                :class="[
                  company.percentage <= 0 ? 'text-success' : 'text-danger',
                  'font-weight-boldest',
                ]"
                >{{
                  company.percentage == null
                    ? "-"
                    : company.percentage.toFixed(2)
                }}%</span
              >
            </h3>
          </div>
        </div>
      </div>
      <!-- year -->
      <div class="row bg-gray-600 rounded d-flex justify-content-center mt-4">
        <h1 class="font-weight-bolder my-auto py-4 text-center text-white">
          {{ thisYear }}
        </h1>
      </div>
      <div class="row text-center bg-white rounded px-3 mx-2 py-10 my-4">
        <!-- consume barchart yesterday today -->
        <apexchart
          type="bar"
          :options="yearChartOptions"
          :series="yearSeries"
          class="w-100"
          height="300"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import EnergyService from "@/core/services/energy.service";
import DashboardUtils from "./utils.js";
import Swal from "sweetalert2";

export default {
  name: "DashboardProduction",
  components: {},

  data() {
    return {
      inProgress: true,
      globalPrediction: {
        today: {
          total: 0,
          percentage: 0,
          events: [],
          companies: [],
        },
        month: {
          companies: [],
          stats: {
            x: [],
          },
        },
      },
      todayChartOptions: {},
      todaySeries: [],
      monthChartOptions: {},
      monthSeries: [],
      yearChartOptions: {},
      yearSeries: [],
      colors: DashboardUtils.colors,
      eventIcons: DashboardUtils.eventIcons,
      today: "",
      thisMonth: "",
      thisYear: "",
      exportInProgress: false,
      exportInProgressDay: false,
    };
  },
  methods: {
    getLanguage() {
      return DashboardUtils.getLanguage();
    },
    exportDashboard() {
      this.exportInProgress = true;
      EnergyService.downloadCsvFile({ type: 2 }).then(() => {
        this.exportInProgress = false;
      }); // 0 - electric, 1 - gas, 2 - solar
    },
    exportDashboardDay() {
      this.exportInProgressDay = true;
      EnergyService.downloadCsvDayFile({ type: 2 }).then(() => {
        this.exportInProgressDay = false;
      }); // 0 - electric, 1 - gas, 2 - solar
    },
    async getGlobalPredictions() {
      this.inProgress = true;
      try {
        this.globalPrediction = await EnergyService.getGlobalPredictions(
          "Solar"
        );
        this.globalPrediction.today.total =
          this.globalPrediction.today.stats[1];
        if (this.globalPrediction.today.stats[0] == 0) {
          this.globalPrediction.today.percentage = null;
        } else {
          this.globalPrediction.today.percentage =
            ((this.globalPrediction.today.stats[1] -
              this.globalPrediction.today.stats[0]) *
              100) /
            Math.abs(this.globalPrediction.today.stats[0]);
        }
        this.globalPrediction.today.percentage =
          this.globalPrediction.today.percentage == null
            ? ""
            : this.globalPrediction.today.percentage.toFixed(2);
        this.inProgress = false;
      } catch (e) {
        this.inProgress = false;
        Swal.fire({
          title: "",
          text: this.$t("ERROR.DEFAULT"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
    },
  },
  async mounted() {
    await this.getGlobalPredictions();

    let title = this.$t("DASHBOARD.PRODUCE");

    this.today = DashboardUtils.today(this.globalPrediction.today.date);
    this.thisMonth = DashboardUtils.thisMonth(
      this.globalPrediction.month.stats.x[0]
    );
    this.thisYear = DashboardUtils.thisYear();

    this.todaySeries = DashboardUtils.getTodaySeries(
      this.globalPrediction,
      this.$t("DASHBOARD.YESTERDAY"),
      this.$t("DASHBOARD.TODAY")
    );
    this.monthSeries = DashboardUtils.getMonthSeries(
      this.globalPrediction,
      title
    );
    this.yearSeries = DashboardUtils.getYearSeries(
      this.globalPrediction,
      this.$t("DASHBOARD.REAL"),
      this.$t("DASHBOARD.PREDICTED")
    );

    this.todayChartOptions = DashboardUtils.getTodayChartOptions(
      this.$t("DASHBOARD.YESTERDAY"),
      this.$t("DASHBOARD.TODAY")
    );
    this.monthChartOptions = DashboardUtils.getMonthChartOptions(
      this.globalPrediction
    );
    this.yearChartOptions = DashboardUtils.getYearChartOptions(
      this.globalPrediction,
      title
    );
  },
};
</script>
<style scoped>
.company {
  border: 1px solid #a4acb3;
}

.font-xxl {
  font-weight: 900;
  font-size: 4rem;
}

.font-xl {
  font-size: 3rem;
}
</style>
